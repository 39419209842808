import React from 'react'

// eslint-disable-next-line react/prop-types
const ContentMapping = () => (
  // eslint-disable-next-line react/prop-types
  <div>
    <img src="/media/zoe-ads-1.jpg" />
    <img src="/media/zoe-ads-2.jpg" />
    <img src="/media/zoe-ads-3.jpg" />
    <img src="/media/zoe-ads-4.jpg" />
    <img src="/media/zoe-ads-5.jpg" />
  </div>
)

export default ContentMapping
